import { DatePicker } from "antd";
import React from "react";
import dayjs from 'dayjs';
import "./WeekFilter.scss";

type WeekFilterProps = {
    setPrevSelectedWeek: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
    setNextSelectedWeek: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
    selectedWeek: dayjs.Dayjs;
    onChange: (date: dayjs.Dayjs, dates: string | string[]) => void;
    format?: string;
    allowClear?: boolean;
};

function WeekFilter(props: Readonly<WeekFilterProps>) {
    const { setPrevSelectedWeek, setNextSelectedWeek, selectedWeek, onChange, format, allowClear } = props;

    const weekFormat = 'MM/DD';
    const customWeekStartEndFormat = (value: any) =>
        `${dayjs(value).startOf('week').format(format ?? weekFormat)} ~ ${dayjs(value)
            .endOf('week')
            .format(format ?? weekFormat)}`;

    return (
        <div className="custom-week-filter d-flex align-items-center">
            <div className='d-flex align-items-center'>
                <button
                    className="left-icon-btn btn btn-custom-primary"
                    type="button"
                    onClick={() => setPrevSelectedWeek((prev) => prev.subtract(1, 'week'))}
                >
                    <span className="btn-text">
                        <i className="ri-arrow-left-double-line"></i>
                    </span>
                </button>
                <DatePicker
                    className="custom-week-picker mx-1"
                    onChange={onChange}
                    picker="week"
                    value={selectedWeek}
                    format={customWeekStartEndFormat}
                    showWeek={false}
                    allowClear={allowClear ?? false}
                />
                <button
                    className={`home-icon-btn btn ${selectedWeek.isSame(dayjs(), 'week') ? 'btn-custom-primary' : 'btn-custom-primary-outline'}`}
                    type="button"
                    onClick={() => setNextSelectedWeek(dayjs())}
                >
                    <span className="btn-text">
                        <i className="ri-calendar-event-line"></i>
                    </span>
                </button>
                <button
                    className="ms-1 right-icon-btn btn btn-custom-primary"
                    type="button"
                    onClick={() => setNextSelectedWeek((prev) => prev.add(1, 'week'))}
                >
                    <span className="btn-text">
                        <i className="ri-arrow-right-double-line"></i>
                    </span>
                </button>
            </div>

        </div>
    )
}

export default WeekFilter